
const rxphone = /^\d{10}$/;
const rxemail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const rxzip = /^\d{5}$/;
var validationComplete = false;
var ndrPerformed = false;

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function revealNext(event){
    event.target.parentNode.classList.add('done');
}

window.onpageshow = function (event) {
    if (event.persisted) {
        // hide modal on back and clear fields
        $('#uform-universalFormPromptModal').modal('hide');
        document.querySelectorAll(".find-a-lawyer-widget form .form-control").forEach(function(el){el.value='';});
        document.querySelectorAll("#uform-universalFormPromptModal textarea").forEach(function(el){el.value='';});
    }

    if (!window.tGetDone) { 
        $.get('/t-get', function(data) {
            window.tGetDone = true;
            // this will set the XSRF-TOKEN cookie in the browser
        })
    }
}
async function validateForm(event, type, optin) {
    event.preventDefault();
    window.dataLayer = window.dataLayer || [];
    var aop_id = document.getElementById('aop_abbreviation').value;
    var inputPhone, inputEmail, inputZip, elementVal, selector, journey_name;

    // if (!window.tGetDone) { 
    //     $.get('/t-get', function(data) {
    //         window.tGetDone = true;
    //         // this will set the XSRF-TOKEN cookie in the browser
    //     })
    // }

    if(type == 'desktop'){
        selector = '.form-connect-with-attorneys.desktop-form';
        journey_name = 'Form';
    } else if (type == 'mobile'){
        selector = '.form-connect-with-attorneys.mobile-form';
        journey_name = 'Form';
    } else if (type == 'top') {
        selector = '#top-form.form-connect-with-attorneys.top-form';
        journey_name = 'Form_Top';
    } else if (type == 'bottom') {
        selector = '.form-connect-with-attorneys.bottom-form';
        journey_name = 'Form_Bottom';
    }

    document.querySelector(selector + " #universal-form-submit").disabled = true;
    document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'none';

    // var iq_endpoint = iqdomain+'/services/v1/recaptcha/verify';

    if (optin == false){
        document.querySelector(selector + ' input[name="sms_optin_status"]').value = '0';
    }

    var full_tcpa = '';
    document.querySelectorAll(selector + " label[for*=leadid_tcpa]").forEach(element => {
        full_tcpa += element.innerText;
        full_tcpa = full_tcpa.replace("Terms of Use", "[Terms of Use](https://www.internetbrands.com/ibterms/)");
        full_tcpa = full_tcpa.replace("Privacy Policy", "[Privacy Policy](https://www.internetbrands.com/privacy/privacy-main)");
        full_tcpa = full_tcpa.replace("privacy policy", "[privacy policy](https://www.internetbrands.com/privacy/privacy-main)");
        full_tcpa = full_tcpa.replace(/\b(click here)\b/gi, function(match){return "[" + match + "](ACTION:SUBMIT_WITHOUT_CONSENT)"});
        full_tcpa = full_tcpa.replace(/(\r\n|\n|\r)/gm, ""); 
        document.querySelector(selector + " input[name='tcpa_consent_verbiage']").value = full_tcpa;
    });

    // //submit if validation was already done;
    // if (validationComplete) {
    //     document.querySelector(selector).submit();
    // }

    document.querySelector(selector + ' .submit-error').classList.add('d-none');
    document.querySelectorAll(selector + ' input[name="phone"]').forEach(function(element) {
        inputPhone = element.value;
        inputPhone == '' || (inputPhone && inputPhone.trim() == '') ? element.classList.add('error') : element.classList.remove('error');
        if(!inputPhone.match(rxphone) && !(inputPhone == '' || (inputPhone && inputPhone.trim() == ''))){
            element.classList.remove('error');
            element.classList.add('valid-error');
        } else {
            element.classList.remove('valid-error');
        }
    });
    document.querySelectorAll(selector + ' input[name="email"]').forEach(function(element) {
        inputEmail = element.value;
        inputEmail == '' || (inputEmail && inputEmail.trim() == '')? element.classList.add('error') : element.classList.remove('error');
        if(!inputEmail.match(rxemail) && !(inputEmail == '' || (inputEmail && inputEmail.trim() == ''))){
            element.classList.remove('error');
            element.classList.add('valid-error');
        } else {
            element.classList.remove('valid-error');
        }
    });
    document.querySelectorAll(selector + ' input[name="zip"]').forEach(function(element) {
        inputZip = element.value;
        inputZip == '' ||(inputZip && inputZip.trim() == '') ? element.classList.add('error') : element.classList.remove('error');
        if(!inputZip.match(rxzip) && !(inputZip == '' ||(inputZip && inputZip.trim() == ''))){
            element.classList.remove('error');
            element.classList.add('valid-error');
        } else {
            element.classList.remove('valid-error');
        }
    });
    document.querySelectorAll(selector + ' input[name="name"]').forEach(function(element) {
        elementVal = element.value;
        elementVal == '' || (elementVal && elementVal.trim() == '')? element.classList.add('error') : element.classList.remove('error');
    });
    document.querySelectorAll(selector + ' textarea[name="description"]').forEach(function(element) {
        elementVal = element.value;
        // elementVal == '' || (elementVal && elementVal.trim() == '') || (elementVal && elementVal.trim().length < 10)? element.classList.add('error') : element.classList.remove('error');

        if(elementVal.trim().length < 10) {
            if (elementVal.trim().length<=0) {
                element.classList.add('valid-error');
            } else {
                element.classList.add('error');
            }
            document.querySelector(selector + " #universal-form-submit").disabled = true;
            document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'none';
        } else {
            element.classList.remove('error');
            document.querySelector(selector + " #universal-form-submit").disabled = false;
            document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';
        }
    });
    if(document.querySelectorAll(selector+' .error').length > 0 || document.querySelectorAll(selector+' .valid-error').length > 0) {
        document.querySelector(selector + " #universal-form-submit").disabled = false;
        document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';

        document.querySelectorAll(selector + ' textarea[name="description"]').forEach(function(element) {
            elementVal = element.value;
            // elementVal == '' || (elementVal && elementVal.trim() == '') || (elementVal && elementVal.trim().length < 10)? element.classList.add('error') : element.classList.remove('error');

            if(elementVal.trim().length < 10) {
                if (elementVal.trim().length<=0) {
                    element.classList.add('valid-error');
                } else {
                    element.classList.add('error');
                }
                document.querySelector(selector + " #universal-form-submit").disabled = true;
                document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'none';
                // EHCS-3465 - Incorrect validation message displayed for less than 10 characters of case description on Top Universal quiz form
                var formStep = element.closest('.modal-body_main-step');
                if(formStep) {
                    var continueButton = formStep.querySelector('div.modal-body_main-step-continueButton');
                    if(continueButton) {
                        continueButton.classList.add('disabled');
                    }
                }

            } else {
                element.classList.remove('error');
                element.classList.remove('valid-error');
                document.querySelector(selector + " #universal-form-submit").disabled = false;
                document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';
                // EHCS-3465 - Incorrect validation message displayed for less than 10 characters of case description on Top Universal quiz form
                var formStep = element.closest('.modal-body_main-step');
                if(formStep) {
                    var continueButton = formStep.querySelector('div.modal-body_main-step-continueButton');
                    if(continueButton) {
                        continueButton.classList.remove('disabled');
                    }
                }
            }
        });

        return false
    } else {
        // var key = document.querySelector('#recaptcha-api').getAttribute('data-key');
        // var groupId = document.querySelector('#recaptcha-api').getAttribute('data-group-id');
        // grecaptcha.ready(function() {
        //     grecaptcha.execute(key, {action: 'submit'}).then(function(capToken) {
        //         fetch(iq_endpoint, {
        //             method: "POST", 
        //             headers: {'Content-Type': 'application/json'},
        //             body: JSON.stringify({
        //                 token: capToken,
        //                 group: groupId,
        //             })
        //         }).then(function(res) {
        //             if(!res.ok){
        //                 document.querySelector(selector + ' .submit-error').classList.remove('d-none');
        //                 return false;
        //             } 
        //             return res.json();
        //         })
        //         .then(function(data){
        //             if (data.data.success && data.data.recaptcha_result){
        //                 if(data.data.recaptcha_result.score > 0.2) document.querySelector(selector).submit();
        //                 else{
        //                     document.querySelector(selector + ' .submit-error').classList.remove('d-none');
        //                     return false;
        //                 }
        //             } else {
        //                 document.querySelector(selector + ' .submit-error').classList.remove('d-none');
        //                 return false;
        //             }
        //         }).catch(function(error) {
        //             document.querySelector(selector + ' .submit-error').classList.remove('d-none');
        //             return false;
        //         })
        //     });
        // });
        if(optin != true) {

            //Check if trusted forms is populated, if not, try to
            if(document.querySelector('input[name="xxTrustedFormCertUrl"]') && document.querySelector("[name='trusted_form_id']").value == ""){
                var trustedFormID = document.querySelector('input[name="xxTrustedFormCertUrl"]').value;
                document.querySelectorAll("[name='trusted_form_id']").forEach(function($element, idx){
                    $element.value = trustedFormID.substring(trustedFormID.lastIndexOf('/')+1);
                }); 
            }

            var rpvPass = false;
            var mailPass = false;

            if($(selector + ' input[name=phone]').length == 0 || $(selector + ' input[name=email]').length == 0){
                $(selector + ' .submit-error').removeClass('d-none');
                return;
            }
            if (!validationComplete) {
                var rpvPhone = await $.ajax({
                    type: 'POST',
                    url: '/rpv-phone',
                    headers: {'X-XSRF-TOKEN': decodeURIComponent(getCookie("XSRF-TOKEN"))},
                    data: {'phone': $(selector + ' input[name=phone]')[0].value},
                    success: function(data) {
                        //rpv passed
                        if (data.responseData.accept == true) {
                            rpvPass = true;
                            if (rpvPass && mailPass) {
                                submitLead(selector);
                            }
                        } else {
                        //rpv failed; clear fields
                            document.querySelectorAll(selector + ' input[name="phone"]').forEach(function (element) {
                                element.value = '';
                                element.classList.add('valid-error');
                            })
                            document.querySelector(selector + " #universal-form-submit").disabled = false;
                            document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';
                            return false;
                        }
                    },
                    error: function(data) {
                        //clear phone field and request to try again
                        document.querySelectorAll(selector + ' input[name="phone"]').forEach(function (element) {
                            element.value = '';
                            element.classList.add('valid-error');
                        })
                        document.querySelector(selector + " #universal-form-submit").disabled = false;
                        document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';
                        return false;
                    }
                });

                //Email validation
                var rpvMail = await $.ajax({
                    type: 'POST',
                    url: '/valid-mail',
                    headers: {'X-XSRF-TOKEN': decodeURIComponent(getCookie("XSRF-TOKEN"))},
                    data: {'email': $(selector + ' input[name=email]')[0].value},
                    success: function(data) {
                        //rpv passed
                        if (data.responseData.valid == true) {
                            mailPass = true;
                            if (rpvPass && mailPass) {
                                submitLead(selector);
                            }
                            return true;
                        } else {
                        //rpv failed; clear fields
                            document.querySelectorAll(selector + ' input[name="email"]').forEach(function (element) {
                                element.value = '';
                                element.classList.add('valid-error');
                            })
                            document.querySelector(selector + " #universal-form-submit").disabled = false;
                            document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';
                            return false;
                        }
                    },
                    error: function(data) {
                        //clear phone field and request to try again
                        document.querySelectorAll(selector + ' input[name="email"]').forEach(function (element) {
                            element.value = '';
                            element.classList.add('valid-error');
                        })
                        document.querySelector(selector + " #universal-form-submit").disabled = false;
                        document.querySelector(selector + " #universal-form-click-here").style.pointerEvents = 'auto';
                        return false;
                    }
                });

                if(rpvPhone.responseData.accept && rpvMail.responseData.valid && mailPass) {
                    validationComplete = true;

                    //get NDR if needed
                    var enable_aop_NDR = [88, 16, 74, 30, 83, 96, 128, 20, 125, 14];
                    var enable_pageType = ['article', 'topic', 'sem-content'];

                    var urlString = window.location.href;
                    var url = new URL(urlString);
                    var params = new URLSearchParams(url.search);

                    if ((enable_aop_NDR.includes(window.NCMS.aopId) && enable_pageType.includes(window.NCMS.pageType)) || window.location.pathname == '/attorney-consult') {
                        ndrPerformed = true;
                        document.querySelector('input[name=ndr_domain]').value = url.host.replace(/^www\./,'');
                        document.querySelector('input[name=ndr_practice_area]').value = window.NCMS.aopId;
                        document.querySelector('input[name=ndr_first_name]').value = document.querySelector(selector + ' input[name=name]').value.split(' ')[0];
                        document.querySelector('input[name=ndr_last_name]').value = document.querySelector(selector + ' input[name=name]').value.split(' ')[1];
                        document.querySelector('input[name=ndr_email]').value = document.querySelector(selector + ' input[name=email]').value;
                        document.querySelector('input[name=ndr_phone]').value = document.querySelector(selector + ' input[name=phone]').value;
                        document.querySelector('input[name=ndr_zip]').value = document.querySelector(selector + ' input[name=zip]').value;
                        document.querySelector('input[name=ndr_src]').value = params.get('source');
                        document.querySelector('input[name=ndr_lnk]').value = params.get('data');
                        document.querySelector('input[name=ndr_case_desc]').value = document.querySelector(selector + ' textarea[name=description]').value.substring(0, 5000);
                    }

                    return true;
                }
            } else {
                submitLead(selector);
            }
        } else {
            submitLead(selector);
            validationComplete = true;
            return true;
        }
    }
}

function submitLead(selector){
    var form = $(selector);

    if (validationComplete == true) {
        if (ndrPerformed) {
            document.querySelector('input[name=ndr_case_desc]').value = document.querySelector(selector + ' textarea[name=description]').value.substring(0,5000);
            document.getElementById("universal_form_w_ndr").submit();
        } else {
            window.location.href = form.find("input[name='redirect']").val();
        }
        return;
    }

    $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        headers: {'X-XSRF-TOKEN': decodeURIComponent(getCookie("XSRF-TOKEN"))},
        data: form.serialize()+"&promptComplete="+validationComplete,
    }).done(function(data){
        if(data.res){
            if (ndrPerformed) {
                document.querySelector('input[name=ndr_case_desc]').value = document.querySelector(selector + ' textarea[name=description]').value.substring(0,5000);
                document.getElementById("universal_form_w_ndr").submit();
            } else {
                window.location.href = form.find("input[name='redirect']").val();
            }
        }
    }).fail(function(data){
        $(selector + ' .submit-error').removeClass('d-none');
    })
}

window.addEventListener('load', function() {
    document.querySelectorAll('input[name="name"]').forEach(function(element) {
        element.addEventListener('blur', function(event){
            if(event.target.value == '' || (event.target.value && event.target.value.trim() == '')){
                event.target.classList.remove('valid-error');
                event.target.classList.add('error');
            } else {
                event.target.classList.remove('error', 'valid-error')
            }
        });
    });
    document.querySelectorAll('input[name="phone"]').forEach(function(element) {
        element.addEventListener('blur', function(event){
            if(event.target.value == '' || (event.target.value && event.target.value.trim() == '')){
                event.target.classList.remove('valid-error');
                event.target.classList.add('error');
            } else {
                event.target.classList.remove('error', 'valid-error')
            }
        });
    });
    document.querySelectorAll('input[name="email"]').forEach(function(element) {
        element.addEventListener('blur', function(event){
            if(event.target.value == '' || (event.target.value && event.target.value.trim() == '')){
                event.target.classList.remove('valid-error');
                event.target.classList.add('error');
            } else {
                event.target.classList.remove('error', 'valid-error')
            }
        });
    });
    document.querySelectorAll('input[name="zip"]').forEach(function(element) {
        element.addEventListener('blur', function(event){
            if(event.target.value == '' || (event.target.value && event.target.value.trim() == '')){
                event.target.classList.remove('valid-error');
                event.target.classList.add('error');
            } else {
                event.target.classList.remove('error', 'valid-error')
            }
        });
    });
    document.querySelectorAll('textarea[name="description"]').forEach(function(element) {
        element.addEventListener('blur', function(event){
            event.target.classList.remove('error', 'valid-error');
            if(event.target.value.trim().length > 0) {
                var formObj = event.target.closest('form');
                if(formObj){
                    var submitButton = formObj.querySelector('button[type="submit"]');
                    if(submitButton) {
                        submitButton.disabled = false;
                        submitButton.style.pointerEvents = 'auto';
                    }
                }
                var formStep = event.target.closest('.modal-body_main-step');
                if(formStep) {
                    var continueButton = formStep.querySelector('div.modal-body_main-step-continueButton');
                    if(continueButton) {
                        continueButton.classList.remove('disabled');
                    }
                }
            }
        });

        element.addEventListener('input', function(event){
            event.target.classList.remove('error', 'valid-error');
            if(event.target.value.trim().length > 0) {
                var formObj = event.target.closest('form');
                if(formObj){
                    var submitButton = formObj.querySelector('button[type="submit"]');
                    if(submitButton) {
                        submitButton.disabled = false;
                        submitButton.style.pointerEvents = 'auto';
                    }

                    var clickHere = formObj.querySelector('#universal-form-click-here');
                    if(clickHere) {
                        clickHere.style.pointerEvents = 'auto';
                    }
                }
                var formStep = event.target.closest('.modal-body_main-step');
                if(formStep) {
                    var continueButton = formStep.querySelector('div.modal-body_main-step-continueButton');
                    if(continueButton) {
                        continueButton.classList.remove('disabled');
                    }
                }
            }
        });
    });
});

window.addEventListener('pageshow', function(){
    document.querySelectorAll('.form-connect-with-attorneys').forEach(function(form){
        form.querySelectorAll('.form-group').forEach(function(formGroup){
            formGroup.classList.remove('done');
        });
    });
});

//EHCS-3419
// const observeTargets = document.querySelectorAll("label[for='leadid_tcpa_disclosure']");
// const options = { threshold: 0.5 };
// function handleTCPAIntersection(entries, observer) {
//     entries.forEach(function(entry) {
//         if (entry.isIntersecting) {
//             var tcpaInput = document.createElement("input");
//             tcpaInput.setAttribute('type', 'hidden');
//             tcpaInput.id = 'leadid_tcpa_disclosure';
//             entry.target.insertAdjacentElement("beforebegin", tcpaInput);
//             observer.disconnect();
//         }
//     });
// };
// const observer = new IntersectionObserver(handleTCPAIntersection, options);

// const disclaimerObservers = [];
// observeTargets.forEach(function(observeTarget) {
//     const observer = new IntersectionObserver(handleTCPAIntersection, options);
//     disclaimerObservers.push(observer);
//     observer.observe(observeTarget);
// });